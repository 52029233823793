import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import UserContext from "../../../../context/user/UserContext";
import { apiGet } from "../../../../shared/api";
import { withRouter } from "react-router-dom";
import DayView from "./MyEntryDogsAndClasses_DayView";
import ModalLoading from "../../../../shared/ModalLoading";
import AsyncSelect from "react-select/async";
import Modal from "../../../../shared/ModalContent";
import moment from "moment";

const MyEntry_DogsAndClasses = ({ Dog, onDog, onHeight, onHandler, ...props }) => {
  const [HeightList, setHeightList] = useState([]);
  const [LevelList, setLevelList] = useState([]);
  const [DayList, setDayList] = useState([]);
  const [AllClassCombinations, setAllClassCombinations] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [DefaultHandler, setDefaultHandler] = useState();
  const [DogId, setDogId] = useState("");
  const [HeightId, setHeightId] = useState("");
  const [DogList, setDogList] = useState([]);
  const [LoadingDogs, setLoadingDogs] = useState(true);
  const [ExistingEntry, setExistingEntry] = useState([]);
  const [ShowModal, setShowModal] = useState(false);
  const [CheckingEntry, setCheckingEntry] = useState(false);
  const [AnyDaysCapped, setAnyDaysCapped] = useState(true);

  const { ShowId } = useParams();
  const { User } = useContext(UserContext);

  const CheckExistingEntry = useCallback(
    async (DogId) => {
      try {
        setCheckingEntry(true);
        const req = await apiGet(`/shows/classes/entries/${ShowId}/${User.Id}`);
        let res = await req.json();
        res = res.filter((r) => r.DogId === parseInt(DogId));
        setExistingEntry(res);
        if (res.length > 0) {
          setHeightId(res[0].HeightId);
          setDefaultHandler({
            value: res[0].HandlerId,
            label: `${res[0].HandlerId} - ${res[0].FirstName} ${res[0].LastName}`,
            name: `${res[0].FirstName} ${res[0].LastName}`,
          });
        } else {
          setHeightId("");
          setDefaultHandler({
            value: User.Id,
            label: `${User.Id} - ${User.FirstName} ${User.LastName}`,
            name: `${User.FirstName} ${User.LastName}`,
          });
        }
      } catch (error) {
        setExistingEntry([]);
        console.log(error.message);
      } finally {
        setCheckingEntry(false);
      }
    },
    [ShowId, User]
  );

  useEffect(() => {
    const found = AllClassCombinations.find((c) => c.Capped);
    setAnyDaysCapped(found ? true : false);
  }, [AllClassCombinations]);

  useEffect(() => {
    setHeightId("");
    if (DogId !== "") CheckExistingEntry(DogId);
  }, [DogId, CheckExistingEntry]);

  useEffect(() => {
    if (User)
      setDefaultHandler({
        value: User.Id,
        label: `${User.Id} - ${User.FirstName} ${User.LastName}`,
        name: `${User.FirstName} ${User.LastName}`,
      });
  }, [User]);

  useEffect(() => {
    onHandler(DefaultHandler);
  }, [DefaultHandler, onHandler]);

  useEffect(() => {
    if (DogId !== "") {
      const foundDog = DogList.find((d) => d.Id === parseInt(DogId));
      if (foundDog) onDog(foundDog);
    }
  }, [DogList, DogId, onDog]);

  useEffect(() => {
    if (HeightId !== "") onHeight(HeightId);
  });

  useEffect(() => {
    (async () => {
      try {
        window.scrollTo(0, 0);

        let req, res;

        setLoading(true);
        setLoadingDogs(true);

        req = await apiGet("/admin/shows/heights");
        res = await req.json();
        setHeightList(res);

        req = await apiGet("/admin/shows/levels");
        res = await req.json();
        setLevelList(res.filter((l) => l.LevelId !== 1));

        req = await apiGet(`/admin/shows/classes/listcombinations/${ShowId}`);
        res = await req.json();
        setAllClassCombinations(res.filter((c) => c.IsEnterable));

        const dtList = new Set();
        for (const itm of res.filter((c) => c.IsEnterable)) {
          dtList.add(itm.ClassDate);
        }
        setDayList([...dtList]);

        //List of all dogs
        req = await apiGet(`/dogs/list/${User.Id}`);
        res = await req.json();
        //Filter dog list for only dogs over 2 years old and active, not already in show
        if (props.LastDay) {
          res = res.filter((d) => d.Status === 1 && moment(props.LastDay).diff(moment(d.DateOfBirth), "months") >= 16);
          setDogList(res);
          setLoadingDogs(false);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
        window.alert("Error loading list of dogs");
      }
    })();
  }, [ShowId, User, props.LastDay]);

  //List of options for dogs
  const DogRows = DogList.map((itm) => (
    <option key={itm.Id} value={itm.Id}>
      {itm.Id} {itm.PetName}
    </option>
  ));

  //Day control for each day of the show
  const DayRows = DayList.map((itm, idx) => {
    return (
      <DayView
        key={`${itm.DogId}_${idx}`}
        ClassDate={itm}
        Dog={Dog}
        Data={{
          MemberId: User.Id,
          ShowId,
          HeightId,
          HeightList,
          LevelList,
          AllClassCombinations,
          SingleDayShow: DayList.length === 1,
          ExistingEntry: ExistingEntry.filter((e) => moment(e.ClassDate).isSame(moment(itm))),
        }}
      />
    );
  });

  //Gets suggested handler names from the server
  const GetHandlerSuggestions = async (val) => {
    try {
      const req = await apiGet(`/members/match/${val}`);
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        return res.map((itm) => {
          const displayString = `${itm.Id} - ${itm.FirstName} ${itm.LastName}`;
          return {
            value: itm.Id,
            label: displayString,
            name: `${itm.FirstName} ${itm.LastName}`,
          };
        });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  //List of dropdown options for height selections
  const HeightRows = () => {
    let availableHeights = [];

    if (Dog) {
      availableHeights = HeightList.reduce(
        (acc, itm) =>
          (itm.IsRegular && itm.SortOrder >= Dog.LowestRegularHeight) ||
          (itm.IsSelect && itm.SortOrder === Dog.LowestSelectHeight)
            ? acc.concat(itm)
            : acc,
        []
      );
    }

    return availableHeights.map((itm) => (
      <option key={itm.HeightId} value={itm.HeightId}>
        {itm.HeightLabel}
      </option>
    ));
  };

  return (
    <>
      {Loading && <ModalLoading />}

      <h3 className="mb-2x">Entry details</h3>

      <p className="mb-1x">
        <b>Instructions</b>
        <br />
        Select the first dog that you wish to enter from the drop-down below. Set your heights and the days you wish to
        enter. <span className="text-red">Then click Enter to finalise entry.</span>
        <br />
        Then repeat the process for each dog you wish to enter classes with.
        <br />
        If you wish to change handler, please type the first few letters of the handlers name in the box and then select
        the correct name.
      </p>
      <p className="alert alert-error">
        <b>Please note:</b> Your entry for a dog is not confirmed until you click Enter for that dog.
      </p>

      <div className="grid mt-2x mb-2x">
        {LoadingDogs && (
          <div className="col-12">
            <p>Loading list of dogs...</p>
          </div>
        )}
        {!LoadingDogs && DogList.length === 0 && (
          <div className="col-12">
            <p>No eligible dogs for this show</p>
          </div>
        )}
        {DogList.length > 0 && (
          <div className="col-3">
            <div className="form-group">
              <label>Which dog do you wish to run?</label>
              <select
                id="SelectedDog"
                className="form-control"
                value={DogId}
                onChange={(e) => setDogId(e.target.value)}>
                <option value="">Please select...</option>
                {DogRows}
              </select>
            </div>
          </div>
        )}
        {DogId && DogId !== 0 && DogList.length > 0 && (
          <>
            <div className="col-3">
              <div className="form-group">
                <label>Which height will you be competing at?</label>
                <select
                  className="form-control"
                  value={HeightId}
                  onChange={(e) => setHeightId(e.target.value)}
                  disabled={CheckingEntry || (AnyDaysCapped && ExistingEntry.length > 0)}>
                  <option value="">Please select...</option>
                  {HeightRows()}
                </select>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Who is running the dog?</label>
                <AsyncSelect
                  noOptionsMessage={() => "Type a name"}
                  placeholder="Start typing"
                  value={DefaultHandler}
                  onChange={(e) => setDefaultHandler(e)}
                  loadOptions={GetHandlerSuggestions}
                  isDisabled={CheckingEntry || (AnyDaysCapped && ExistingEntry.length > 0)}
                />
              </div>
            </div>
          </>
        )}
      </div>

      {ExistingEntry.length > 0 && (
        <p className="mt-1x mb-2x">
          You have an existing entry for this dog. If you want to change any details, please do so as necessary and then
          click 'Enter'. This will overwrite your existing entry for this dog.
        </p>
      )}

      {CheckingEntry && <p>Checking entry status...</p>}

      {ShowModal && (
        <Modal>
          {ExistingEntry.map((itm, idx) => (
            <p>
              {moment(itm.ClassDate).format("ddd Do MMM")} {itm.ClassLabel} {itm.LevelLabel} {itm.HeightLabel}
            </p>
          ))}
          <p className="mt-3x">
            <button className="button" onClick={() => setShowModal(false)}>
              OK
            </button>
          </p>
        </Modal>
      )}

      {DogId !== "" && HeightId !== "" ? DayRows : <></>}
    </>
  );
};

export default withRouter(MyEntry_DogsAndClasses);
