import React, { useState, useEffect, useContext, useCallback } from "react";
import { apiGet, apiPost } from "../../../../shared/api";
import UserContext from "../../../../context/user/UserContext";
import InlineLoading from "../../../../shared/InlineLoading";

const SummaryPostage = (props) => {
  const [Required, setRequired] = useState(false);
  const [ShowAlter, setShowAlter] = useState(false);
  const [PostageString, setPostageString] = useState("");
  const [Loading, setLoading] = useState(false);

  const { User } = useContext(UserContext);

  const CanEdit = props.CanEdit;

  const LoadData = useCallback(async () => {
    try {
      setLoading(true);

      const req = await apiGet(`/shows/entry/${props.ShowId}/${User.Id}`);
      const res = await req.json();
      setRequired(res.PostRunningOrders);

      setPostageString(
        `You've opted ${res.PostRunningOrders ? "" : "not"} to receive running orders by post.${
          res.PostRunningOrders ? " (£2.00)" : ""
        }`
      );

      setLoading(false);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading show data");
      setLoading(false);
    }
  }, [props.ShowId, User.Id]);

  useEffect(() => {
    if (props.ShowId) LoadData();
  }, [props.ShowId, LoadData]);

  const SavePostage = async () => {
    try {
      const obj = {
        ShowId: props.ShowId,
        MemberId: User.Id,
        Required,
      };

      const req = await apiPost("/shows/updatepostsettings", obj);
      if (req && req.ok) {
        setShowAlter(false);
        LoadData();

        props.RefreshCost();
      } else {
        window.alert("Error saving postage settings");
      }

      props.onSave();
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving postal settings");
    }
  };

  return (
    <div className="mb-3x">
      <h3>Postage</h3>

      {Loading ? <InlineLoading /> : <></>}

      <p>{PostageString}</p>
      {!ShowAlter && CanEdit && !props.IsClosed ? (
        <button className="button" onClick={() => setShowAlter(true)}>
          Alter my choice
        </button>
      ) : (
        <></>
      )}
      {ShowAlter ? (
        <>
          <div className="form-group">
            <label>Please send me running orders for this show by post</label>
            <label className="switch">
              <input type="checkbox" checked={Required} onChange={(e) => setRequired(e.target.checked)} />
              <span className="slider round"></span>
            </label>
          </div>
          <p>
            <button className="button button-green" onClick={SavePostage}>
              Save choice
            </button>
          </p>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SummaryPostage;
