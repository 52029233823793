import React, { useState, useEffect } from "react";
import { apiGet } from "../../shared/api";
import { useHistory } from "react-router-dom";
import moment from "moment";

const SelectShow = () => {
  const [ShowId, setShowId] = useState("0");
  const [Shows, setShows] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const itm = sessionStorage.getItem("ShowDate");
      let chosenDate;
      if (itm) {
        chosenDate = moment(itm, "YYYYMMDD");
      } else {
        chosenDate = moment.utc();
        sessionStorage.setItem("ShowDate", moment.utc().format("YYYYMMDD"));
      }

      const req = await apiGet("/shows/list?FutureOnly=0");
      let res = await req.json();

      //const startDate = moment().add(-14, "day");
      const startDate = moment().add(-12, "months");
      res = res.filter((s) => moment(s.StartDate).isAfter(startDate));
      const showList = res.reduce((acc, itm) => {
        if (chosenDate.isBetween(moment.utc(itm.StartDate), moment.utc(itm.EndDate), "day", "[]")) acc.push(itm);
        return acc;
      }, []);
      setShows(showList);
    })();
  }, [history]);

  const SelectShow = () => {
    sessionStorage.setItem("ShowId", parseInt(ShowId));
    history.push("/liveresults/classlist");
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4>Select a show</h4>
      </div>
      <div className="card-body">
        <p>Which show do you wish to view results for?</p>
        <p>
          <select className="form-control width-25" value={ShowId} onChange={(e) => setShowId(e.target.value)}>
            <option value="0">Select...</option>
            {Shows.map((itm) => (
              <option key={itm.ShowId} value={itm.ShowId}>
                {itm.DisplayName}
              </option>
            ))}
          </select>
        </p>
        <p>
          {ShowId !== "0" && (
            <button className="button button-green" onClick={SelectShow}>
              Select Show
            </button>
          )}
        </p>
      </div>
    </div>
  );
};

export default SelectShow;
