import React, { useState, useEffect } from "react";

const MyEntryPostage = (props) => {
  const [Required, setRequired] = useState(props.Postage);

  const SetRequired = (val) => {
    setRequired(val);
    props.SetPostage(val);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.SetCanContinue(true);
  }, [props]);

  return (
    <div className="mb-3x">
      <h3>Postage</h3>
      <div className="form-group">
        <label>Please send me running orders for this show by post (£2.00)</label>
        <label className="switch">
          <input type="checkbox" checked={Required} onChange={(e) => SetRequired(e.target.checked)} />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default MyEntryPostage;
