import React, { useState, useRef } from "react";
import { useInterval } from "beautiful-react-hooks";

const TotalCost = ({ Postage }) => {
  const [Cost, setCost] = useState(0);
  const DaysInShow = useRef(0);

  useInterval(() => {
    const output = [];
    let itm = sessionStorage.getItem("Classes");
    if (itm) {
      itm = JSON.parse(itm);
      if (itm.length > 0) {
        DaysInShow.current = itm.filter((i) => i.Classes.length > 0).length;
      }
      for (const dt of itm) {
        for (const cls of dt.Classes) output.push(cls);
      }
    }

    const classCost =
      output.length === 0
        ? 0
        : (output.reduce((acc, itm) => (acc += itm.RunCost), 0) / output.length) * DaysInShow.current;

    const postageCost = Postage ? 2 : 0;

    itm = sessionStorage.getItem("CampingCost");
    const campingCost = itm ? parseFloat(itm) : 0;
    setCost(classCost + postageCost + campingCost);
  }, 2000);

  return <h3 className="mt-2x mb-2x">Total cost for this dog: £{Cost.toFixed(2)}</h3>;
};

export default TotalCost;
